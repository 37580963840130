import { useSelector } from 'react-redux';

/* useCurrentUser()
 *
 * Returns the current User.
 * Returns undefined if not found.
 */
export const useCurrentUser = () => {
  const session = useSelector(state => state.session);
  const users = useSelector(state => state.users);

  if (!session.get('is_logged_in')) return;

  return users.get(session.get('current_user_id'));
};


export const useTutorModeEnabled = () => useSelector(state => state.users.getIn([state.session.get('current_user_id'), 'tutor_mode_enabled'], false));
