/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Map } from "immutable";
import { Link } from "react-router-dom";
import classNames from "classnames";

import SVGIcon from "components/SVGIcon";
import { useFirstViewableListItemIndex } from 'hooks/helpers';
import useWindowDimensions, { breakpoints } from "helpers/windowDimensions";


// StyleGuide = molecules/lists/table

// TableColumn is a non-rendering Component
// It only exists to define the structure for the <Table> component
const TableColumn = () => null;

/* eslint-disable react/no-unused-prop-types, react-redux/no-unused-prop-types */
TableColumn.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  expandRow: PropTypes.bool,
  sortable: PropTypes.oneOfType([
    PropTypes.bool, // uses same result as cellValue unless a string or function is provided
    PropTypes.string,
    PropTypes.func
  ]),
  sortDefault: PropTypes.oneOf([
    false,
    true, // same as "desc"
    "asc",
    "desc"
  ]),
  cellValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]).isRequired,
  cellDefault: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ])
};
/* eslint-enable react/no-unused-prop-types, react-redux/no-unused-prop-types */

TableColumn.defaultProps = {
  cellDefault: '',
  className: '',
  cellClassName: '',
  sortable: false,
  sortDefault: false,
  expandRow: false
};

export { TableColumn };

const TableExpandableRow = () => null;

/* eslint-disable react/no-unused-prop-types, react-redux/no-unused-prop-types */
TableExpandableRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  rowValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]).isRequired,
  rowDefault: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]),
  rowClassName: PropTypes.string
};
/* eslint-enable react/no-unused-prop-types, react-redux/no-unused-prop-types */

TableExpandableRow.defaultProps = {
  rowDefault: '',
  className: '',
  rowClassName: ''
};

export { TableExpandableRow };


/* eslint-disable-next-line react/no-multi-comp */
const Table = ({
  rowData,
  className,
  noHeader,
  zebra,
  scrollable,
  border,
  rowLinkTo,
  rowDisable,
  rowOnClick,
  refs,
  highlightId,
  children,
  refContainer,
  scrollToRef,
  fullScreen,
  onHover,
  onScroll,
  maxRecords,
  lastElementRef,
  disableAriaLabel,
  sortCallback,
  selectable,
  setSelectedRows,
  selectedRows
}) => {
  const { width: screenWidth } = useWindowDimensions();
  const isSmallScreen = screenWidth <= breakpoints.sm;
  // Make sure we have an array of TableColumns from children
  // TODO: Should filter out components which aren't instances of TableColumn. Enforcing this with PropTypes is not supported.
  const tableColumns = (!Array.isArray(children) ? [children] : children)
    .filter(child => child)
    .map(child => ({ ...child.props, key: child.key }));

  // Determine key for each TableColumn (if not provided)
  tableColumns.forEach((tableColumn, i) => {
    if (!tableColumn.key) {
      const { cellValue } = tableColumn;
      tableColumn.key = typeof cellValue === 'function' ? i : cellValue;
    }
  });

  // Determine default sortKey and sortDirection for Table
  const defaultSortColumn = tableColumns.find(tableColumn => tableColumn.sortDefault);
  const defaultSortKey = defaultSortColumn ? defaultSortColumn.key : null;
  const defaultSortDirection = defaultSortColumn && defaultSortColumn.sortDefault === "asc" ? -1 : 1;
  const [sortKey, setSortKey] = useState(defaultSortKey);
  const [sortDirection, setSortDirection] = useState(defaultSortDirection);
  const [upperLimit, setUpperLimit] = useState(maxRecords || Infinity);
  const adjustSpliceRange = useCallback((idx) => {
    if (upperLimit < idx + (maxRecords / 2)) {
      setUpperLimit(maxRecords + idx);
    }
  }, [upperLimit]);
  const { refContainer: maxRecordRef, debouncedScroll } = useFirstViewableListItemIndex(adjustSpliceRange, [upperLimit]);

  const onClickSort = (key, direction) => {
    if (key !== sortKey) {
      setSortKey(() => key);
      setSortDirection(1);
    } else if (direction !== sortDirection) { setSortDirection(direction); }
  };

  useEffect(() => {
    if (maxRecords) {
      setUpperLimit(maxRecords);
    }
  }, [rowData.length]);

  useEffect(() => {
    const paginationSortDirection = [-1, 0].includes(sortDirection) ? 0 : 1;
    if (sortKey) sortCallback(`${sortKey},${paginationSortDirection}`);
  }, [sortKey, sortDirection]);

  // Handlers for checkbox selection
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const selectableRows = rowData.filter(row => row.get('role') !== 'super admin').map(row => row.get('id'));
      setSelectedRows(selectableRows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSelectRow = (event, rowId) => {
    event.persist();

    setSelectedRows(prevSelectedRows => (event.target.checked ? [...prevSelectedRows, rowId] : prevSelectedRows.filter(id => id !== rowId)));
  };

  // Determine which TableColumn we are sorting by (if any)
  const sortChild = tableColumns.find(tableColumn => tableColumn.sortable && tableColumn.key === sortKey);

  // Apply sort order if needed
  const sortRows = (a, b, tableColumn, direction) => {
    // Determine sortable values
    const { sortable, cellValue } = tableColumn;
    const [sortA, sortB] = (() => {
      if (typeof sortable === 'function') return [sortable(a), sortable(b)];
      if (typeof sortable === 'string') return [a.get(sortable), b.get(sortable)];
      if (typeof cellValue === 'function') return [cellValue(a), cellValue(b)];
      if (Map.isMap(a) && Map.isMap(b)) return [a.get(cellValue), b.get(cellValue)];
      return [a[cellValue], b[cellValue]];
    })();

    // Tie
    if (sortA === sortB) {
      // If we have a default sort column then we use it as the tiebreaker (unless we're already sorting with it)
      if (defaultSortColumn && defaultSortKey !== sortKey && tableColumn !== defaultSortColumn) {
        return sortRows(a, b, defaultSortColumn, defaultSortDirection);
      }
      return 0;
    }

    let result = sortA > sortB ? -1 : 1;
    // Apply sort direction
    result *= direction === 1 ? -1 : 1;
    // Reverse when sorting numeric values
    if (typeof sortA === 'number') result *= -1;
    return result;
  };
  const sortedRowData = sortChild ? rowData.sort((a, b) => sortRows(a, b, sortChild, sortDirection)) : rowData;
  // Ref prop
  const containerRef = refContainer || maxRecordRef;

  const renderHeader = (tableColumn, i) => {
    const {
      children: headerChildren,
      className: headerClassName,
      key,
      sortable
    } = tableColumn;

    let ariaSort = 'none';

    if (i === 0 && selectable) {
      return (
        <div
          tabIndex={0}
          role="columnheader"
          key={i}
          onClick={e => handleSelectAll(e)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleSelectAll(e);
            }
          }}
          className={`col ${headerClassName}`}
          {...{ "aria-sort": ariaSort }}
        >
          <input
            type="checkbox"
            checked={
              selectedRows.length === rowData.filter(row => row.get('role') !== 'super admin').length
                && selectedRows.length > 0
            }
            onChange={e => handleSelectAll(e)}
          />
        </div>
      );
    }

    const handleKeyPres = (ev) => {
      if ([32, 13].includes(ev.keyCode)) {
        onClickSort(key, +!sortDirection);
      }
    };

    // UpCaret default on Quircles Column doesn't display for QuestionList ... why?
    let caret = (<SVGIcon name="RightCaret1" width={12} height={12} minWidth={12} ariaLabel="Sort" />);
    if (sortKey === key) {
      if (sortDirection !== 1) {
        caret = (<SVGIcon name="UpCaret1" width={12} height={12} minWidth={12} ariaLabel="Sort" />);
        ariaSort = 'ascending';
      } else {
        caret = (<SVGIcon name="DownCaret1" width={12} height={12} minWidth={12} ariaLabel="Sort" />);
        ariaSort = 'descending';
      }
    }

    return (
      /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions  */
      <div tabIndex={0} role="columnheader" key={i} onClick={sortable ? () => onClickSort(key, +!sortDirection) : null} onKeyDown={sortable ? handleKeyPres : null} className={`col ${headerClassName}`} {...{ 'aria-sort': ariaSort }}>
        { headerChildren }
        { sortable && caret }
        {ariaSort !== 'none' && (<span className="screenreader" role="alert" aria-live="assertive">{ariaSort}</span>)}
      </div>
    );
  };

  const renderCell = (row, cellValue, cellDefault, i) => {
    if (typeof cellValue === 'function') return cellValue(row, i);
    if (typeof cellValue === 'string') {
      if (Map.isMap(row) && (row.get(cellValue) || row.get(cellValue) === 0)) return row.get(cellValue);
      if (row[cellValue]) return row[cellValue];
    }
    return cellDefault;
  };

  const renderCells = (row, i) =>
    tableColumns.map((tableColumn, j) => {
      if (tableColumn.expandRow) {
        return (
          <div tabIndex={0} role="cell" className="row w-100" key={j}>
            { renderCell(row, tableColumn.cellValue, tableColumn.cellDefault, i) }
          </div>
        );
      }
      return (
        <div tabIndex={0} role="cell" className={`col ${tableColumn.className} ${tableColumn.cellClassName}`} key={j}>
          { renderCell(row, tableColumn.cellValue, tableColumn.cellDefault, i) }
        </div>
      );
    });

  const renderRow = (row, i) => {
    if (row === undefined || row === null) {
      return null;
    }
    const rowId = Map.isMap(row) ? row.get('id') : row.id || 0;
    /* This whole ref thing is for scrolling, right now have it working fine in question list which is scrollToRef.
      The refs prop and the containerRef are how it works in the performance timeline which perhaps can be
      done the same way scrollToRef is done but not sure because the scroll is not on the page but in an element
    */
    let ref = refs ? refs[rowId] : React.createRef();
    ref = scrollToRef && scrollToRef[rowId] ? scrollToRef[rowId] : ref;
    ref = lastElementRef && rowData.length === i + 1 ? lastElementRef : ref;
    const highlight = rowId === highlightId && highlightId ? 'highlight' : "";
    const rowTableClass = classNames(`row table-row ${isSmallScreen ? 'h-auto' : ''}`, { highlight, 'border-bottom': border });
    const rowDisabled = rowDisable && rowDisable(row);
    const hoverEvents = onHover ? {
      onFocus: () => onHover(row.get('id')),
      onMouseOver: () => onHover(row.get('id'))
    } : {};

    const handleKeyPres = (ev) => {
      if ([32, 13].includes(ev.keyCode)) {
        rowOnClick(row, ev);
      }
    };

    if (selectable) {
      return (
        <div
          role="row"
          className={`row table-row ${isSmallScreen ? "h-auto" : ""} ${highlight} ${
            border ? "border-bottom" : ""
          }`}
          key={rowId}
        >
          <div
            tabIndex={0}
            role="cell"
            className={`col ${tableColumns[0].className} ${tableColumns[0].cellClassName}`}
          >
            {row.get('role') !== 'super admin' && (
              <input
                type="checkbox"
                checked={selectedRows.includes(row.get('id'))}
                onChange={e => handleSelectRow(e, row.get('id'))}
              />
            )}
          </div>
          {renderCells(row, i)}
        </div>
      );
    }

    if (rowLinkTo && !rowDisabled) {
      if (rowOnClick) {
        return (
          <Link role="row" key={i} ref={ref} className={`${rowTableClass} btn d-flex btn-none`} to={rowLinkTo(row)} onClick={ev => rowOnClick(ev)}>{ renderCells(row, i) }</Link>
        );
      }

      return (
        <Link role="row" id={row.get('id')} {...hoverEvents} key={i} ref={ref} className={`${rowTableClass} btn d-flex btn-none`} to={rowLinkTo(row)}>{ renderCells(row, i) }</Link>
      );
    }
    if (rowOnClick && !rowDisabled) {
      return (
        <div key={i} ref={ref} {...hoverEvents} {...(!disableAriaLabel && { 'aria-label': `Go To ${row.get('name')}` })} className={`${rowTableClass} pad-top-btm clickable-row`} role="button" tabIndex={0} onClick={ev => rowOnClick(row, ev)} onKeyDown={handleKeyPres}>{ renderCells(row, i) }</div>
      );
    }
    return <div role="row" key={i} ref={ref} className={`${rowTableClass} pad-top-btm`}>{ renderCells(row, i) }</div>;
  };

  const tableClass = classNames('Table', {
    container: !fullScreen,
    'container-fluid': fullScreen,
    [className]: className
  });

  const handleScroll = (e) => {
    if (onScroll) onScroll(e);
    if (maxRecords) debouncedScroll(e);
  };

  const useOneScroll = !!onScroll || !!maxRecords;

  return (
    <div role="table" className={tableClass}>
      <div role="row" className={`row table-header-row ${noHeader ? 'd-none' : ''} ${scrollable ? 'scrollable' : ''} ${border ? 'border-bottom' : ''}`}>
        {selectable ? ([
          tableColumns.filter(tc => !tc.expandRow)[0], ...tableColumns.filter(tc => !tc.expandRow)].map(renderHeader)
        ) : (
          tableColumns.filter(tc => !tc.expandRow).map(renderHeader)
        )}
      </div>
      <div role="rowgroup" ref={containerRef} {...(useOneScroll ? { onScroll: e => handleScroll(e) } : {})} className={`position-relative columns-container ${scrollable ? 'scrollable' : ''} ${zebra ? 'zebra' : ''}`}>
        { sortedRowData.slice(0, upperLimit).map(renderRow) }
      </div>
    </div>
  );
};

Table.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.bool]))
  ]).isRequired,
  rowData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string])).isRequired,
  rowLinkTo: PropTypes.func,
  rowOnClick: PropTypes.func,
  rowDisable: PropTypes.func,
  className: PropTypes.string,
  zebra: PropTypes.bool,
  scrollable: PropTypes.bool,
  refs: PropTypes.object,
  refContainer: PropTypes.object,
  scrollToRef: PropTypes.object,
  highlightId: PropTypes.number,
  maxRecords: PropTypes.number,
  border: PropTypes.bool,
  fullScreen: PropTypes.bool,
  onHover: PropTypes.func,
  onScroll: PropTypes.func,
  noHeader: PropTypes.bool,
  lastElementRef: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disableAriaLabel: PropTypes.bool,
  sortCallback: PropTypes.func,
  selectable: PropTypes.bool,
  setSelectedRows: PropTypes.func,
  selectedRows: PropTypes.arrayOf(PropTypes.number)
};

Table.defaultProps = {
  className: "",
  zebra: false,
  disableAriaLabel: false,
  sortCallback: () => {},
  selectable: false,
};

export default Table;
