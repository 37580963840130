import Factory from 'factories/_Factory';


export default class MemberGroup extends Factory {
  static jsonType = 'member_group';

  static get defaults() {
    return {
      name: '',
      archived: false,
      creator_email: '',
      has_more_members_pagination: true,
    };
  }

  static get belongsTo() {
    return ['organization', 'creator'];
  }

  static get hasMany() {
    return ['delegate', 'member'];
  }
}
