import { Map, List, Set } from 'immutable';
import { createSelector } from 'reselect';

import { createMultiSelector } from 'helpers/reselect';
import { getServerTimeNow } from 'helpers/dateTime';
import { idObjectToNestedMaps } from 'helpers/immutableHelpers';
import capitalize from 'helpers/stringHelper';
import { quizPerformanceUseAsPerformanceQuestion } from 'helpers/quizzes';
import { DateTime } from 'luxon';


// Direct Selectors
// These selectors return unmodified Immutable objects directly from the Redux Store.
const selectQuiz = (state, quizId) => state.quizzes.get(quizId);
const selectQuizQuestionCount = (state, quizId) => state.quizzes.getIn([quizId, 'total_questions'], 0);
const selectQuizType = (state, quizId) => state.quizzes.getIn([quizId, 'type']);
const selectQuizPerformanceResponses = (state, quizId) => state.performanceResponses.get(`${quizId}`, new Map());
const selectQuizQuestionBank = (state, quizId) => state.questionBanks.get(state.quizzes.getIn([quizId, 'question_bank_id']));
const selectQuizAssignment = (state, quizId) => {
  const assignmentId = state.quizzes.getIn([quizId, 'assignment_id']);
  return state.assignments.get(assignmentId);
};
const selectQuizCreator = (state, quizId) => {
  const assignmentId = state.quizzes.getIn([quizId, 'assignment_id']);
  const creatorId = state.assignments.getIn([assignmentId, 'creator_id']);
  return state.users.get(creatorId);
};
const selectQuizUser = (state, quizId) => {
  const userId = state.quizzes.getIn([quizId, 'user_id']);
  return state.users.get(userId);
};
const selectQuizPracticeExamTemplate = (state, quizId) => {
  const practiceExamTemplateId = state.quizzes.getIn([quizId, 'practice_exam_template_id']);
  return state.practiceExamTemplates.get(practiceExamTemplateId);
};

// This assumes that only Subjects belonging to the selected QuestionBank are loaded
// AND that the provided Quiz belongs to the selected QuestionBank.
const selectSubjects = state => state.subjects;
const selectPerformanceQuestions = state => state.performanceQuestions;
const selectPerformancePeers = state => state.performancePeers;


export const selectQuizProgress = createMultiSelector([
  selectQuiz,
  selectQuizPerformanceResponses,
  selectQuizQuestionCount,
  selectQuizType,
  selectPerformanceQuestions,
  selectQuizAssignment
], (
  quiz,
  performanceResponses,
  questionCount,
  quizType,
  performanceQuestions,
  assignment
) => {
  if (!quiz) return undefined;
  const tutorQuiz = quizType === 'tutor';

  const quizPerformance = {
    score: null,
    questionCount,
    answeredCount: 0,
    unansweredCount: 0,
    correctCount: 0,
    partialCorrectCount: 0,
    incorrectCount: 0,
    averageResponseTime: 0,
    potentialScore: 0,
    actualScore: 0
  };


  /*

  PerformanceResponse Format =

  [
    ms_spent,
    extra_ms_spent,
    answered_at,
    correct,
    answered,
    user_evaluated,
    is_aact,
    potential_score,
    actual_score
  ]

  */

  const responseTimes = [];
  const aactResponses = performanceResponses.filter(r => r.get(6) === true);
  const hasAactResponses = aactResponses.size > 0;

  quiz.get('question_ids', new List()).forEach((questionId) => {
    const response = performanceResponses.get(`${questionId}`);
    const question = performanceQuestions.get(questionId);

    if (response) {
      quizPerformance.actualScore += response.get(8);
      const userEvaluatedTutorResponse = tutorQuiz && response.get(5) === true;
      const answered = (response.get(10) && response.get(4)) || userEvaluatedTutorResponse;
      if (answered) quizPerformance.answeredCount += 1;
      if (response.get(6) === true && answered) {
        if (response.get(9) === 1) quizPerformance.incorrectCount += 1;
        if (response.get(9) === 3) quizPerformance.partialCorrectCount += 1;
      } else if (response?.get(3) === false && answered) {
        quizPerformance.incorrectCount += 1;
      }

      if (response?.get(3) === true) quizPerformance.correctCount += 1;

      responseTimes.push(response.get(0));
    }
    if (!question) return;
    quizPerformance.potentialScore += question.get('potentialScore');
  });

  // Calculate Average Response Time
  quizPerformance.averageResponseTime = responseTimes.length === 0 ? 0 : responseTimes.reduce((prev, current) => prev + current, 0) / responseTimes.length;

  // Calculate Quiz Score
  if (quizPerformance.questionCount > 0) {
    quizPerformance.score = hasAactResponses ? quizPerformance.actualScore / quizPerformance.potentialScore : quizPerformance.correctCount / questionCount;
    quizPerformance.unansweredCount = questionCount - quizPerformance.answeredCount;
  }

  return new Map(quizPerformance);
});


// This selector always causes a re-render because it creates a new object each time it is used.
// It is recommended to avoid using it and instead render an array of Components which each use the singular selector.
export const selectQuizProgresses = state => state.quizzes.map((quiz, quizId) => selectQuizProgress(state, quizId));


// selectNoPauseTimeLeft is a cache-busting selector. It forces recalculation every second for in-progress no-pause Quizzes
const selectNoPauseTimeSpent = (state, quizId) => {
  const assignment = selectQuizAssignment(state, quizId);
  const quiz = selectQuiz(state, quizId);

  // isBlockQuiz logic copied from below, but should it actually check for blocks? Should it matter?
  const isBlockQuiz = !!quiz?.get('practice_exam_template_id');
  if (isBlockQuiz || !assignment?.get('no_pause')) return 0;

  const time = Math.ceil((getServerTimeNow() - quiz.get('started_at')) / 1000);
  return time > 0 ? time : 0;
};

// selectOverdue is a cache-busting selector. It forces recalculation for Quizzes with due dates when the due date passes.
const selectOverdue = (state, quizId) => {
  const quiz = selectQuiz(state, quizId);
  const assignment = selectQuizAssignment(state, quizId);
  const dueAt = assignment?.get('end_time');
  const accommodatedCutoffTime = quiz?.get('cutoff_time');
  if (accommodatedCutoffTime) return DateTime.fromISO(accommodatedCutoffTime) < getServerTimeNow();
  if (!dueAt) return false;
  return getServerTimeNow() >= dueAt;
};


// Quiz Selector Debugging Variables
const debugQuizReselect = false;
const allPrevArgs = {};

export const selectQuizWithRelationships = createMultiSelector([
  (state, quizId) => quizId,
  selectQuiz,
  selectQuizAssignment,
  selectQuizCreator,
  selectQuizUser,
  selectQuizPracticeExamTemplate,
  selectQuizProgress,
  selectNoPauseTimeSpent,
  selectOverdue
], (
  quizId,
  quiz,
  assignment,
  creator,
  user,
  practiceExamTemplate,
  quizProgress,
  noPauseTimeSpent,
  overdue
) => {
  // Quiz Selector Debugging Output
  // Logs the changes which caused reselection
  if (debugQuizReselect) {
    const currentArgs = {
      quizId,
      quiz,
      assignment,
      creator,
      user,
      practiceExamTemplate,
      quizProgress,
      noPauseTimeSpent,
      overdue
    };

    if (!allPrevArgs[quizId]) {
      console.log('selectQuizWithRelationships()', quizId, 'FIRST');
      allPrevArgs[quizId] = currentArgs;
    } else {
      const prevArgs = allPrevArgs[quizId];
      const diffs = {};
      Object.keys(currentArgs).forEach((k) => {
        const prevVal = prevArgs[k];
        const curVal = currentArgs[k];
        if (curVal !== prevVal) {
          if (Map.isMap(prevVal) && Map.isMap(curVal)) {
            const mapKeys = new Set(prevVal.keySeq().concat(curVal.keySeq())).toArray();
            let foundMapDiff = false;
            mapKeys.forEach((mapKey) => {
              const prevMapValue = prevVal.get(mapKey);
              const curMapValue = curVal.get(mapKey);
              if (prevMapValue !== curMapValue) {
                foundMapDiff = true;
                diffs[k + '.' + mapKey] = [
                  prevMapValue?.toJS ? prevMapValue.toJS() : prevMapValue,
                  curMapValue?.toJS ? curMapValue.toJS() : curMapValue
                ];
              }
            });
            if (!foundMapDiff) {
              diffs[k] = 'New Map, Same Values';
            }
          } else {
            diffs[k] = [
              prevVal?.toJS ? prevVal.toJS() : prevVal,
              curVal?.toJS ? curVal.toJS() : curVal
            ];
          }
        }
      });
      console.log('selectQuizWithRelationships()', quizId, diffs);
      allPrevArgs[quizId] = currentArgs;
    }
  }


  if (!quiz) return undefined;
  const isPractice = !!quiz.get('practice_exam_template_id');
  const quizBlocks = quiz.get('quiz_blocks', new Map());
  const questionIds = quiz.get('question_ids', new List());
  const isBlockQuiz = isPractice; // should this actually check for blocks?
  const isTimed = quiz.get('seconds_per_question') !== null;
  const dueAt = assignment?.get('end_time');
  const questionCount = questionIds.size;
  const isAssignment = !!quiz.get('assignment_id');
  const isLti = isAssignment ? assignment.get('is_lti') : false;
  const isBlockPooled = quiz.get('block_pool_seconds_remaining') !== null;
  const isBreakPooled = quiz.get('break_pool_seconds_remaining') !== null;
  const totalBlockSeconds = practiceExamTemplate?.get('block_pool_time_in_seconds');
  const totalBreakSeconds = practiceExamTemplate?.get('break_pool_time_in_seconds');
  const isNgn = quiz.get('is_ngn');

  const currentQuizBlock = quizBlocks.find(b => b.get('position') === quiz.get('last_quiz_block_number'));

  const timeLimit = () => {
    const limit = quiz.get('seconds_per_question') * quiz.get('total_questions');
    return limit > 0 ? limit : 0;
  };

  const blockQuizSecondsRemaining = () => {
    let timeUsed = 0;
    if (quiz.get('block_pool_seconds_remaining') >= 0) {
      timeUsed += quiz.get('block_pool_seconds_remaining');
    }
    if (quiz.get('break_pool_seconds_remaining')) {
      timeUsed += quiz.get('break_pool_seconds_remaining');
    }
    return timeUsed;
  };

  const inQuestionBlock = () => {
    if (!isBlockQuiz || !currentQuizBlock) return false;
    return currentQuizBlock.get('question_ids', new List()).size;
  };

  const totalBlockTime = () => {
    if (!currentQuizBlock) return 0;
    if (inQuestionBlock()) {
      if (isBlockPooled) {
        return totalBlockSeconds;
      }
      return currentQuizBlock.get('total_seconds');
    }
    if (isBreakPooled) {
      return totalBreakSeconds;
    }
    return currentQuizBlock.get('total_seconds');
  };

  const blockTimeSpent = () => {
    if (!currentQuizBlock) return 0;
    if (inQuestionBlock) {
      if (isBlockPooled) {
        return totalBlockTime() - quiz.get('block_pool_seconds_remaining');
      }
      return currentQuizBlock.get('total_seconds') - currentQuizBlock.get('seconds_remaining');
    }
    if (isBreakPooled) {
      return totalBlockTime() - quiz.get('break_pool_seconds_remaining');
    }
    return currentQuizBlock.get('total_seconds') - currentQuizBlock.get('seconds_remaining');
  };


  const blockQuizTimeLeft = () => {
    if (!isBlockQuiz) return false;
    return totalBlockTime() - blockTimeSpent();
  };

  const timeSpent = () => {
    if (!quiz.get('started_at')) return 0;
    let time = 0;
    if (isBlockQuiz) {
      time = blockQuizSecondsRemaining();
    } else {
      time = assignment?.get('no_pause') ? noPauseTimeSpent : timeLimit() - quiz.get('seconds_remaining');
    }
    return time > 0 ? time : 0;
  };

  const onLastQuizBlock = () => {
    if (!isBlockQuiz || !currentQuizBlock) return false;
    return currentQuizBlock.get('position') === quizBlocks.size;
  };

  const timeLeft = () => {
    if (isBlockQuiz) return blockQuizTimeLeft();
    return timeLimit() - timeSpent();
  };

  const blockQuizEndOfCurrentTime = () => {
    if (!isBlockQuiz) return false;
    return blockQuizTimeLeft() <= 0;
  };

  const blockQuizOutOfTime = () => {
    if (isBlockPooled) {
      return quiz.get('block_pool_seconds_remaining') <= 0;
    }
    if (onLastQuizBlock() && currentQuizBlock.get('seconds_remaining') <= 0) {
      return true;
    }
    return false;
  };

  const questionBlocks = () => {
    if (!isBlockQuiz) return new Map();
    return quizBlocks.filter(qb => qb.get('question_ids').size);
  };

  const outOfTime = () => {
    if (!isTimed) return false;
    if (isPractice) return blockQuizOutOfTime();
    return timeLeft() <= 0;
  };

  // This is more accurate than `.status` especially for no-pause and assignments with due dates
  const currentStatus = () => {
    if (quiz.get('status') === 'incomplete' && (overdue || outOfTime())) return 'ready-for-submission';
    return quiz.get('status');
  };

  const typeLabel = () => {
    switch (quiz.get('type')) {
      case 'Timed':
      case 'Untimed':
        return "Test";
      case 'Review':
        return "Study";
      case 'Adaptive':
        return "CAT";
      default:
        return capitalize(quiz.get('type'));
    }
  };

  const boardExamInterface = () => {
    if (quiz.get('status') === 'complete' || !quiz.get('board_exam_interface')) return 'enhanced-learning';
    return quiz.get('board_exam_interface');
  };

  const currentBlockQuestionIds = () => {
    if (!isBlockQuiz) return;
    if (inQuestionBlock()) {
      return currentQuizBlock.get('question_ids');
    }
    return false;
  };

  const readyToSubmit = currentStatus() === 'ready-for-submission';
  const isNewAssignment = isAssignment && currentStatus() === 'incomplete' && quiz.get('started_at') === 0;

  const autoSubmitAssignment = () => isAssignment && quiz.get('status') !== 'complete' && overdue;

  const currentQuestionBlockIndex = () => questionBlocks().toList().sortBy(qb => qb.get('position')).findIndex(qb => qb.get('id') === currentQuizBlock.get('id'));

  const breakBlocks = () => {
    if (!isBlockQuiz) return false;
    return quizBlocks.filter(qb => !qb.get('question_ids').size);
  };

  const quizBlocksRemaining = () => quizBlocks.filter(qb => qb.get('position') >= quiz.get('last_quiz_block_number'));

  const questionBlocksRemaining = () => quizBlocksRemaining().filter(qb => qb.get('question_ids').size).sortBy(qb => qb.get('position'));

  const breakBlocksRemaining = () => quizBlocksRemaining().filter(qb => !qb.get('question_ids').size).sortBy(qb => qb.get('position'));

  const blockQuizTotalTimeElapsed = () => {
    const totalTime = quizBlocks.reduce((r, qb) => r + qb.get('total_seconds'), 0);
    const totalTimeUsed = quizBlocks.reduce((r, qb) => r + qb.get('seconds_remaining'), 0);
    return totalTime - totalTimeUsed;
  };

  const blockQuizTimeExceeded = () => {
    if (!currentQuizBlock) return 0;
    if (currentQuizBlock.get('seconds_remaining') > 0) return 0;
    const blocksExceeding = quizBlocksRemaining().filter(qb => qb.get('position') > quiz.get('last_quiz_block_number'));
    const totalTime = blocksExceeding.reduce((r, qb) => r + qb.get('total_seconds'), 0);
    const totalTimeUsed = blocksExceeding.reduce((r, qb) => r + qb.get('seconds_remaining'), 0);
    return totalTime - totalTimeUsed;
  };

  const invalidBlock = () => {
    const blocksAhead = quizBlocksRemaining().filter(qb => qb.get('position') > quiz.get('last_quiz_block_number')).sortBy(qb => qb.get('position'));
    const totalTime = blocksAhead.reduce((r, qb) => r + qb.get('total_seconds'), 0);
    const totalTimeUsed = blocksAhead.reduce((r, qb) => r + qb.get('seconds_remaining'), 0);
    return totalTime !== totalTimeUsed;
  };

  const nextValidBlock = () => {
    if (!invalidBlock()) return false;
    const blocksAhead = quizBlocksRemaining().filter(qb => qb.get('position') > quiz.get('last_quiz_block_number')).sortBy(qb => qb.get('position'));
    const tickedBlocks = blocksAhead.filter(qb => (qb.get('total_seconds') > qb.get('seconds_remaining'))).sortBy(qb => qb.get('position'));
    return tickedBlocks.last();
  };

  return quiz.merge(quizProgress).merge({
    is_ngn: isNgn,
    autoSubmitAssignment: autoSubmitAssignment(),
    boardExamInterface: boardExamInterface(),
    creator_id: assignment?.get('creator_id'),
    user_id: user?.get('id'),
    currentStatus: currentStatus(),
    due_at: dueAt,
    first_name: creator?.get('first_name'),
    initial_message: assignment?.get('initial_message'),
    isAssignment: isAssignment,
    isBlockQuiz: isBlockQuiz,
    isNewAssignment: isNewAssignment,
    isPractice: isPractice,
    isTimed: isTimed,
    last_name: creator?.get('last_name'),
    no_pause: assignment?.get('no_pause'),
    organization_id: assignment?.get('organization_id'),
    outOfTime: outOfTime(),
    overdue,
    questionCount: questionCount,
    readyToSubmit: readyToSubmit,
    strike_throughs: quiz.get('strike_throughs'),
    timeLeft: timeLeft(),
    timeLimit: timeLimit(),
    timeSpent: timeSpent(),
    typeLabel: typeLabel(),
    blockQuizEndOfCurrentTime: blockQuizEndOfCurrentTime(),
    blockQuizOutOfTime: blockQuizOutOfTime(),
    blockQuizSecondsRemaining: blockQuizSecondsRemaining(),
    blockQuizTimeExceeded: blockQuizTimeExceeded(),
    blockQuizTimeLeft: blockQuizTimeLeft(),
    blockQuizTotalTimeElapsed: blockQuizTotalTimeElapsed(),
    blockTimeSpent: blockTimeSpent(),
    breakBlocks: breakBlocks(),
    breakBlocksRemaining: breakBlocksRemaining(),
    currentBlockQuestionIds: currentBlockQuestionIds(),
    currentQuestionBlockIndex: currentQuestionBlockIndex(),
    currentQuizBlock: currentQuizBlock,
    inQuestionBlock: inQuestionBlock(),
    invalidBlock: invalidBlock(),
    isBlockPooled: isBlockPooled,
    isBreakPooled: isBreakPooled,
    isLti: isLti,
    nextValidBlock: nextValidBlock(),
    onLastQuizBlock: onLastQuizBlock(),
    questionBlocks: questionBlocks(),
    questionBlocksRemaining: questionBlocksRemaining(),
    quiz_blocks: quizBlocks,
    quizBlocksRemaining: quizBlocksRemaining(),
    total_block_seconds: practiceExamTemplate?.get('block_pool_time_in_seconds'),
    total_break_seconds: practiceExamTemplate?.get('break_pool_time_in_seconds'),
    totalBlockTime: totalBlockTime(),
  });
});


// This selector always causes a re-render because it creates a new object each time it is used.
// It is recommended to avoid using it and instead render an array of Components which each use the singular selector.
export const selectQuizzesWithRelationships = state => state.quizzes
  .filter(quiz => quiz.get('question_bank_id') === state.session.get('selected_question_bank_id'))
  .map(quiz => selectQuizWithRelationships(state, quiz.get('id')));


// This selector always causes a re-render because it creates a new object each time it is used.
// It is recommended to avoid using it and instead render an array of Components which each use the singular selector.
export const selectCurrentQuizzesWithRelationships = state => state.quizzes
  .filter(quiz =>
    quiz.get('question_bank_id') === state.session.get('selected_question_bank_id')
    && quiz.get('user_id') === state.session.get('current_user_id'))
  .map(quiz => selectQuizWithRelationships(state, quiz.get('id')));


// This selector always causes a re-render because it creates a new object each time it is used.
// It is recommended to avoid using it and instead render an array of Components which each use the singular selector.
export const selectPracticeExams = state => state.quizzes
  .filter(quiz => quiz.get('practice_exam_template_id')
    && !quiz.get('archived')
    && state.session.get('selected_question_bank_id') === quiz.get('question_bank_id'))
  .map(quiz => selectQuizWithRelationships(state, quiz.get('id')));


export const selectQuizPerformance = createSelector([
  selectQuiz,
  selectQuizPerformanceResponses,
  selectSubjects,
  selectPerformanceQuestions,
  selectQuizQuestionBank,
  selectQuizAssignment
], (
  quiz,
  performanceResponses,
  selectedSubjects,
  selectedQuestions,
  questionBank,
  assignment
) => {
  if (!quiz) return undefined;
  const tutorQuiz = quiz.get('type') === 'tutor';
  const isNgn = quiz?.get('is_ngn');
  const defaultPerformanceAttributes = {
    questionCount: 0,
    correctCount: 0,
    // partial correct is not fully implemented
    partialCorrectCount: 0,
    incorrectCount: 0,
    answeredCount: 0,
    unansweredCount: 0,
    score: 0,
    potentialScore: 0,
    totalMsSpent: 0,
    averageMsSpent: 0,
    averageDifficulty: 0
  };

  /*
    performanceResponse = [
      ms_spent,
      extra_ms_spent,
      answered_at,
      correct,
      answered,
      evaluated_at
    ]
  */

  function addResponseToPerformanceAttributes(performanceAttributes, response, questionId, question) {
    performanceAttributes.questionCount += 1;
    const userEvaluatedTutorResponse = tutorQuiz && response?.get(5) === true;
    const answered = (response?.get(10) && response?.get(4)) || userEvaluatedTutorResponse;

    if (answered) performanceAttributes.answeredCount += 1;
    if (response?.get(6) === true && answered) {
      if (response.get(9) === 1) performanceAttributes.incorrectCount += 1;
      if (response.get(9) === 3) performanceAttributes.partialCorrectCount += 1;
    } else if (response?.get(3) === false && answered) {
      performanceAttributes.incorrectCount += 1;
    }
    if (response?.get(3) === true) performanceAttributes.correctCount += 1;
    performanceAttributes.potentialScore += question.get('potentialScore');
    if (response) performanceAttributes.score += response?.get(8);

    performanceAttributes.totalMsSpent += response?.get(0) || 0;
  }

  function calculatePerformanceAttributes(performanceAttributesGroup, weightedScore) {
    Object.values(performanceAttributesGroup).forEach((performanceAttributes) => {
      performanceAttributes.unansweredCount = performanceAttributes.questionCount - performanceAttributes.answeredCount;
      if (performanceAttributes.answeredCount > 0) {
        if (weightedScore && !isNgn) {
          performanceAttributes.score = Math.round(performanceAttributes.correctCount / performanceAttributes.answeredCount * 100);
        }
        performanceAttributes.averageMsSpent = performanceAttributes.totalMsSpent / performanceAttributes.answeredCount;
      }
      if (performanceAttributes.questionCount > 0 && !weightedScore && !isNgn) {
        performanceAttributes.score = Math.round(performanceAttributes.correctCount / performanceAttributes.questionCount * 100);
      }
    });

    return idObjectToNestedMaps(performanceAttributesGroup);
  }

  let totalMsSpent = 0;
  let averageMsSpent = 0;
  const subjectPerformances = {};
  const quizBlockPerformances = {};
  // Extract data from Responses
  quiz.get('question_ids', new List()).forEach((questionId) => {
    const question = selectedQuestions.get(questionId) ? selectedQuestions.get(questionId) : quizPerformanceUseAsPerformanceQuestion(quiz, questionId);
    const response = performanceResponses.get(`${questionId}`);
    if (!question) return;

    totalMsSpent += response?.get(0) || 0;

    // Extract data into subjectPerformances
    question.get('subjectIds', new List()).forEach((subjectId) => {
      const subject = selectedSubjects.get(subjectId);
      if (!subject) return;

      // Create default subjectPerformance object if it does not exist
      if (!subjectPerformances[subjectId]) {
        subjectPerformances[subjectId] = {
          subjectId,
          ...defaultPerformanceAttributes
        };
      }

      addResponseToPerformanceAttributes(subjectPerformances[subjectId], response, questionId, question);
    });

    // Extract data into quizBlockPerformances
    const quizBlock = quiz.get('quiz_blocks', new Map()).find(qb => qb.get('question_ids').includes(questionId));
    if (quizBlock) {
      // Create default quizBlockPerformance object if it does not exist
      if (!quizBlockPerformances[quizBlock.get('id')]) {
        quizBlockPerformances[quizBlock.get('id')] = {
          quiz_block_id: quizBlock.get('id'),
          ...defaultPerformanceAttributes
        };
      }
      addResponseToPerformanceAttributes(quizBlockPerformances[quizBlock.get('id')], response, questionId, question);
    }
  });

  if (quiz.get('total_questions', 0) > 0) {
    averageMsSpent = totalMsSpent / quiz.get('total_questions', 0);
  }
  const isLti = quiz.get('assignment_id') ? assignment.get('is_lti') : false;
  return new Map({
    totalMsSpent,
    averageMsSpent,
    isLti,
    subjectPerformances: calculatePerformanceAttributes(subjectPerformances, true),
    quizBlockPerformances: calculatePerformanceAttributes(quizBlockPerformances, false)
  });
});


export const selectPracticeExamPerformance = createSelector([
  selectQuiz,
  selectQuizQuestionBank,
  selectPerformancePeers,
  selectQuizPerformance
], (
  quiz,
  questionBank,
  performancePeers,
  quizPerformance
) => {
  if (!quiz) return undefined;

  // Add Peer Rank to Practice Exam Performances

  if (!questionBank) return quizPerformance;

  const practiceExamPerformance = {
    peerAverage: null,
    peerRank: null,
    peerStandardDeviation: null
  };

  const peerRankThreshold = questionBank.get('peer_rank_threshold', 100);


  const peerScore = Math.round(quiz.get('score') * 100);

  let totalPeers = 1;
  let lesserPeers = 0;
  let totalPeerScores = peerScore;

  performancePeers.forEach((performancePeer) => {
    if (performancePeer.get('practice_exam_template_id') !== quiz.get('practice_exam_template_id')) return;

    totalPeers += performancePeer.get('users_count');
    totalPeerScores += performancePeer.get('score') * performancePeer.get('users_count');
    if (performancePeer.get('score') <= peerScore) lesserPeers += performancePeer.get('users_count');
  });

  if (totalPeers > peerRankThreshold) {
    practiceExamPerformance.peerAverage = (totalPeerScores + peerScore) / totalPeers;
    practiceExamPerformance.peerRank = Math.round(lesserPeers / (totalPeers - 1) * 100);

    // Calculate Standard Deviation
    const totalDeviation = performancePeers.reduce((sum, performancePeer) => {
      const deviation = (performancePeer.get('score') - practiceExamPerformance.peerAverage) ** 2;
      return sum + (deviation * performancePeer.get('users_count'));
    }, 0) + ((peerScore - practiceExamPerformance.peerAverage) ** 2);

    practiceExamPerformance.peerStandardDeviation = Math.sqrt(totalDeviation / totalPeers);
  }

  return quizPerformance.merge(practiceExamPerformance);
});
