import { List } from 'immutable';
import Factory from 'factories/_Factory';
import Image from 'factories/Image';

export default class Organization extends Factory {
  static jsonType = 'organization';

  static afterUpdate(record, json) {
    let newRecord = record;
    const { attributes } = json;
    if (attributes.images || newRecord.get('images') === undefined) {
      const images = (attributes.images || []).map(image => new Image(image));
      newRecord = newRecord.set('images', new List(images));
    }

    if (attributes.student_purchase_option_ids || newRecord.get('student_purchase_option_ids') === undefined) {
      newRecord = newRecord.set('student_purchase_option_ids', new List(attributes.student_purchase_option_ids));
    }

    return newRecord;
  }

  static get defaults() {
    return {
      name: '',
      visible_banks: '',
      hide_cme: '',
      view_quizzes_enabled: false,
      tutor_mode: false,
      has_more_members_pagination: true,
      has_more_member_groups_pagination: true,
      total_available_students: 0,
      total_available_staff: 0,
      lti_enabled: false
    };
  }

  static get hasMany() {
    return ['organizational_subscription', 'member', 'member_group'];
  }
}
