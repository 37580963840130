import api, {
  currentUserUrl,
  selectedOrganizationUrl,
} from 'helpers/api.js';

import { createApiRequestAction } from 'helpers/createAction';


export const MEMBER_GROUPS_FETCHING = 'MEMBER_GROUPS_FETCHING';
export const MEMBER_GROUPS_FETCHED = 'MEMBER_GROUPS_FETCHED';
export const MEMBER_GROUPS_FETCH_ERROR = 'MEMBER_GROUPS_FETCH_ERROR';
export const MEMBER_GROUPS_PAGINATED = 'MEMBER_GROUPS_PAGINATED';
export const memberGroupsFetch = createApiRequestAction({
  getKey: ({ organizationId, query, pageNumber } = {}) => `organizations/${organizationId}/member-groups?query=${query}&page=${pageNumber}`,
  request: (getState, {
    organizationId, loadUsers, organization, query, pageNumber, sortKey
  } = {}) => {
    const options = {
      params: {
        load_users: loadUsers, query, organization, page: pageNumber, sort_key: sortKey
      }
    };
    return api.get(`${currentUserUrl(getState())}/organizations/${organizationId}/member_groups`, options);
  },
  loadingConstant: MEMBER_GROUPS_FETCHING,
  loadedConstant: MEMBER_GROUPS_FETCHED,
  errorConstant: MEMBER_GROUPS_FETCH_ERROR,
  paginationEndConstant: MEMBER_GROUPS_PAGINATED,
});


export const MEMBER_GROUP_FETCHING = 'MEMBER_GROUP_FETCHING';
export const MEMBER_GROUP_FETCHED = 'MEMBER_GROUP_FETCHED';
export const MEMBER_GROUP_FETCH_ERROR = 'MEMBER_GROUP_FETCH_ERROR';
export const memberGroupFetch = createApiRequestAction({
  getKey: ({
    memberGroupId, loadUsers
  } = {}) => `organizations/member-groups/${memberGroupId}?load_users=${loadUsers}`,
  request: (getState, {
    memberGroupId, loadUsers
  } = {}) => {
    const options = {
      params: {
        load_users: loadUsers
      }
    };
    return api.get(`${selectedOrganizationUrl(getState())}/member_groups/${memberGroupId}`, options);
  },
  loadingConstant: MEMBER_GROUP_FETCHING,
  loadedConstant: MEMBER_GROUP_FETCHED,
  errorConstant: MEMBER_GROUP_FETCH_ERROR,
});

export const MEMBER_GROUP_USERS_FETCHING = 'MEMBER_GROUP_USERS_FETCHING';
export const MEMBER_GROUP_USERS_FETCHED = 'MEMBER_GROUP_USERS_FETCHED';
export const MEMBER_GROUP_USERS_FETCH_ERROR = 'MEMBER_GROUP_USERS_FETCH_ERROR';
export const MEMBER_GROUP_USERS_PAGINATED = 'MEMBER_GROUP_USERS_PAGINATED';
export const memberGroupUsersFetch = createApiRequestAction({
  getKey: ({
    organizationId,
    memberGroupId,
    pageNumber,
    query
  } = {
  }) => `organizations/${organizationId}/member_groups/${memberGroupId}/members?query=${query}&page=${pageNumber}`,
  request: (getState, {
    organizationId, memberGroupId, pageNumber, query
  } = {}) => {
    const options = {
      params: {
        page: pageNumber,
        query: query
      }
    };
    return api.get(`${currentUserUrl(getState())}/organizations/${organizationId}/member_groups/${memberGroupId}/members`, options);
  },
  loadingConstant: MEMBER_GROUP_USERS_FETCHING,
  loadedConstant: MEMBER_GROUP_USERS_FETCHED,
  errorConstant: MEMBER_GROUP_USERS_FETCH_ERROR,
  paginationEndConstant: MEMBER_GROUP_USERS_PAGINATED,
});


export const MEMBER_GROUP_CREATING = 'MEMBER_GROUP_CREATING';
export const MEMBER_GROUP_CREATED = 'MEMBER_GROUP_CREATED';
export const MEMBER_GROUP_CREATE_ERROR = 'MEMBER_GROUP_CREATE_ERROR';
export const memberGroupCreate = createApiRequestAction({
  getKey: organizationId => `organizations/${organizationId}/member-groups/create`,
  request: (getState, organizationId, params) =>
    api.post(`${currentUserUrl(getState())}/organizations/${organizationId}/member_groups`, { member_group: params }),
  loadingConstant: MEMBER_GROUP_CREATING,
  loadedConstant: MEMBER_GROUP_CREATED,
  errorConstant: MEMBER_GROUP_CREATE_ERROR,
});


export const MEMBER_GROUP_UPDATING = 'MEMBER_GROUP_UPDATING';
export const MEMBER_GROUP_UPDATED = 'MEMBER_GROUP_UPDATED';
export const MEMBER_GROUP_UPDATE_ERROR = 'MEMBER_GROUP_UPDATE_ERROR';
const updateActionConstants = {
  loadingConstant: MEMBER_GROUP_UPDATING,
  loadedConstant: MEMBER_GROUP_UPDATED,
  errorConstant: MEMBER_GROUP_UPDATE_ERROR,
};

export const ORGANIZATION_UNLOAD_MEMBER_GROUPS = 'ORGANIZATION_UNLOAD_MEMBER_GROUPS';
export const organizationUnloadMemberGroups = () => (dispatch, getState) => {
  const { loadedAt, session } = getState();
  const organizationId = session.get('selected_organization_id');
  const key = memberGroupsFetch.getKey({
    organizationId,
    query: '',
    pageNumber: 1
  });
  const orgMemberGroupsLoaded = loadedAt.get(key);
  if (orgMemberGroupsLoaded) {
    dispatch({
      type: ORGANIZATION_UNLOAD_MEMBER_GROUPS,
      payload: { organizationId }
    });
  }
};

export const ORGANIZATION_UNLOAD_MEMBER_GROUP_USERS = 'ORGANIZATION_UNLOAD_MEMBER_GROUP_USERS';
export const organizationUnloadMemberGroupUsers = classId => (dispatch, getState) => {
  const { loadedAt, session } = getState();
  const organizationId = session.get('selected_organization_id');
  const memberGroupId = classId;
  const key = memberGroupUsersFetch.getKey({
    organizationId,
    memberGroupId,
    pageNumber: 1,
  });
  const orgMemberGroupsLoaded = loadedAt.get(key);
  if (orgMemberGroupsLoaded) {
    dispatch({
      type: ORGANIZATION_UNLOAD_MEMBER_GROUP_USERS,
      payload: { memberGroupId }
    });
  }
};

export const memberGroupUpdate = createApiRequestAction({
  getKey: memberGroupId => `organizations/member-groups/${memberGroupId}/update`,
  request: (getState, memberGroupId, params) =>
    api.patch(`${selectedOrganizationUrl(getState())}/member_groups/${memberGroupId}`, { member_group: params }),
  ...updateActionConstants,
});

export const memberGroupAssociate = createApiRequestAction({
  getKey: memberGroupId => `organizations/member-groups/${memberGroupId}/associate`,
  request: (getState, memberGroupId) => api.patch(`${selectedOrganizationUrl(getState())}/member_groups/${memberGroupId}/associate`),
  ...updateActionConstants,
});

export const memberGroupArchive = createApiRequestAction({
  getKey: memberGroupId => `organizations/member-groups/${memberGroupId}/archive`,
  request: (getState, memberGroupId) => api.patch(`${selectedOrganizationUrl(getState())}/member_groups/${memberGroupId}/archive`),
  ...updateActionConstants,
});

export const memberGroupUnarchive = createApiRequestAction({
  getKey: memberGroupId => `organizations/member-groups/${memberGroupId}/unarchive`,
  request: (getState, memberGroupId) => api.patch(`${selectedOrganizationUrl(getState())}/member_groups/${memberGroupId}/unarchive`),
  ...updateActionConstants,
});
