import { List } from 'immutable';

import Factory from 'factories/_Factory';


export default class User extends Factory {
  static jsonType = 'user';

  static afterUpdate(record, json) {
    let newRecord = record;

    if (json.attributes.whitelisted_question_bank_ids || newRecord.get('whitelisted_question_bank_ids') === undefined) {
      newRecord = newRecord.set('whitelisted_question_bank_ids', new List(json.attributes.whitelisted_question_bank_ids));
    }

    if (json.attributes.marked_question_ids || newRecord.get('marked_question_ids') === undefined) {
      newRecord = newRecord.set('marked_question_ids', new List(json.attributes.marked_question_ids));
    }

    if (json.attributes.marked_clinical_pearl_ids || newRecord.get('marked_clinical_pearl_ids') === undefined) {
      newRecord = newRecord.set('marked_clinical_pearl_ids', new List(json.attributes.marked_clinical_pearl_ids));
    }

    if (json.attributes.practice_exam_qb_ids || newRecord.get('practice_exam_qb_ids') === undefined) {
      newRecord = newRecord.set('practice_exam_qb_ids', new List(json.attributes.practice_exam_qb_ids));
    }

    return newRecord;
  }

  static get defaults() {
    return {
      email: '',
      first_name: '',
      last_name: '',
      is_onboarded_mobile: false,
      user_type: '',
      sub_user_type: '',
      internal_role: '',
      is_from_ati_platform: false, // This attribute is deprecated and should no longer be used (required still by mobile app)
      tutor_mode_enabled: false,
      branding_theme: false,
      own_member_group_ids: [],
      archived_member_group_ids: [],
      delegated_member_group_ids: [],
    };
  }
}
