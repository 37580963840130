import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";

import Badge from 'components/Badge';
import SVGIcon from 'components/SVGIcon';
import { svgProps } from 'components/icons/SvgContainer';


// StyleGuide = molecules/menus/nav-link

class NavLink extends React.Component {
  render() {
    const {
      children,
      className,
      disabled,
      active,
      to,
      href,
      target,
      onClick,
      styleVariant,
      notificationQuantity,
      icon,
      iconProps,
      role,
      ariaControls,
      ariaExpanded,
      ariaLevel
    } = this.props;

    const finalClassName = classNames(`NavLink nav-item style-${styleVariant}`, className, {
      disabled,
      active,
      'nav-link': (to || href || onClick) && styleVariant !== 'dropdown-item',
      'dropdown-item': styleVariant === 'dropdown-item'
    });

    let dropProps = {};
    if (ariaControls) {
      dropProps = {
        'aria-controls': ariaControls,
        'aria-expanded': ariaExpanded
      };
    }

    const finalChildren = (
      <React.Fragment>
        { (styleVariant === "settings" && icon) && (
          <SVGIcon className="mr-2" name={icon} {...iconProps} />
        )}
        { children }
        { ((styleVariant !== "settings" || styleVariant === "storefrontMobile") && icon) && <SVGIcon className="ml-2" name={icon} {...iconProps} /> }
        { notificationQuantity && <Badge className="ml-2" styleVariant="light">{notificationQuantity}</Badge> }
      </React.Fragment>
    );

    if (to && !disabled) {
      return (
        <Link
          className={finalClassName}
          to={to}
          onClick={onClick}
        >{ finalChildren }
        </Link>
      );
    }

    if (href && !disabled) {
      return (
        <a
          className={finalClassName}
          href={href}
          target={target}
          onClick={onClick}
        >{ finalChildren }
        </a>
      );
    }

    if (onClick && !disabled) {
      let ariaSelected = {};
      if (role === "tab") {
        ariaSelected = {
          'aria-selected': active,
          'aria-controls': ariaControls
        };
      }

      return (
        <button
          role={role}
          type="button"
          className={finalClassName}
          onClick={onClick}
          {...ariaSelected}
          {...dropProps}
        >{ finalChildren }
        </button>
      );
    }

    return (
      <div {...(ariaLevel && { 'aria-level': ariaLevel })} className={finalClassName}>{ finalChildren }</div>
    );
  }
}

NavLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  href: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  notificationQuantity: PropTypes.number,
  icon: PropTypes.string,
  iconProps: PropTypes.shape({ ...svgProps }),
  styleVariant: PropTypes.oneOf([
    "default",
    "settings",
    "QuestionModalTabs",
    "dropdown-item",
    "storefront",
    "storefrontMobile"
  ]),
  role: PropTypes.string,
  ariaControls: PropTypes.string,
  ariaExpanded: PropTypes.bool,
  ariaLevel: PropTypes.number
};

NavLink.defaultProps = {
  className: "",
  styleVariant: "default",
  iconProps: { width: 12, height: 12 },
  role: "button",
  ariaControls: "",
  ariaExpanded: false
};

export default NavLink;
